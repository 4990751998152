@tailwind base;
@tailwind components;
@tailwind variants;
@tailwind utilities;

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "flatpickr/dist/flatpickr.css";

@import '~datatables.net-dt/css/jquery.dataTables';
@import '~datatables.net-buttons-dt/css/buttons.dataTables';


